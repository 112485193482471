import { useDisclosure } from '@chakra-ui/hooks'
import React, { useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  Box,
  Heading,
} from "@chakra-ui/react"
import { Controller, useForm, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useLocalStorage } from 'react-use'
import { StaticImage } from 'gatsby-plugin-image'
import { httpsCallable } from '@firebase/functions'

import FormField from '../../../components/FormField'
import CopiableText from '../../../components/CopiableText'
import FullpageHeight from '../../../components/FullpageHeight'
import DrawButton from '../../../components/DrawButton'
// import DrawRadio from '../../../components/DrawRadio'
import DrawArrowButton from '../../../components/DrawArrowButton'
import { responsive } from '../../../components/ThemeProvider/theme'
import withFunctions from '../../../utils/withFunctions'
import sanitize from '../../../utils/sanitize'

const schema = yup.object({
  name: yup.string().required('必填'),
  email: yup.string().email('請填入正確E-mail格式').required('必填'),
  inviteeEmail: yup.string().email('請填入正確E-mail格式').required('必填'),
  // relationship: yup.string().required(),
}).required();

const fields = [
  { name: 'name', label: '你的名字（會出現在給對方的邀請信中）', placeholder: '你的姓名、暱稱' },
  { name: 'email', label: '你的Email是？', type: 'email', placeholder: '你的E-mail' },
  { name: 'inviteeEmail', label: '對方的Email是？', type: 'email', placeholder: '對方的E-mail' },
  // { name: 'relationship', label: '你們的關係是？', type: 'select', options: ['關係1', '關係2', '關係3'] },
]

const Invite = ({ location: { state }, pageContext, functions }) => {
  const [invitationSent, setInviteId] = useLocalStorage(`superquiz:love-languages:${state?.firestoreId}:invited`)
  const saveData = functions && httpsCallable(functions, 'saveFirestoreData')

  const { isOpen, onClose, onOpen } = useDisclosure()
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: '',
      email: state?.email,
      inviteeEmail: '',
    },
    resolver: yupResolver(schema),
  })
  const { isSubmitting } = useFormState({ control })
  const onSubmit = async data => {
    const id = await saveData({
      collection: 'invitations',
      answerId: state.firestoreId,
      ...sanitize(data),
    })

    setInviteId(id?.data)
  };

  return (
    <FullpageHeight maxW="container.sm">
      {invitationSent ? (
        <VStack pt="4" px="10%" flex="1" justifyContent="center" spacing="4">
          <Box width="50%">
            <StaticImage src="./love-envelope.png" alt="愛心信封" layout="fullWidth" />
          </Box>
          <Heading letterSpacing="widest" color="love.primary" fontWeight="900" fontSize="2xl">邀請信已為你發出</Heading>
          <Text color="love.text" fontSize="1.125em" fontWeight="700">
            你也可以直接
            <CopiableText mx="1" color="love.primary" display="inline-flex" text={`https://superquiz.do/q/love-languages/${pageContext.id}/invitation/?key=${invitationSent}`}>複製專屬連結</CopiableText>
            <br />邀請對方！
          </Text>
          <Box />
          <DrawButton onClick={onOpen}>邀請其他對象參與分析</DrawButton>
          <DrawButton to={`/q/love-languages/${pageContext.id}/result/`} state={state}>回到結果頁</DrawButton>
        </VStack>
      ) : (
        <>
          <VStack as="form" width="100%" flex="1" pt="2em" pb="4em" onSubmit={handleSubmit(onSubmit)} spacing="4" color="love.text" alignItems="flex-start" justifyContent="center">
            <DrawArrowButton color="love.text" onClick={() => window.history.back()} />
            {fields.map(f => (
              <Controller
                name={f.name}
                control={control}
                key={f.name}
                render={({ field, fieldState }) => (
                  <FormField
                    bg="white"
                    {...f}
                    {...field}
                    fieldState={fieldState}
                  />
                )}
              />
            ))}
            <VStack px="2" width="full" spacing="6" pt={responsive(16, 30)}>
              {/* <DrawRadio
                type="checkbox"
                {...register('agree')}
                color="love.text"
              >我同意將測驗結果紀錄於Email，以方便下次分析其他對象結果使用</DrawRadio> */}
              <DrawButton type="submit" isLoading={isSubmitting}>送出邀請</DrawButton>
            </VStack>
          </VStack>
        </>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader color="love.text">已成功寄出邀請</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody pt="12" as={VStack} spacing="0.75em">
            <Text fontS fontWeight="700" color="love.text">若你想邀請其他對象，建議你重新測驗，面對不同對象，你的感受可能不一樣喔</Text>
          </ModalBody>
          <ModalFooter as={VStack}>
            <DrawButton to={`/q/love-languages/${pageContext.id}/dual/`}>重做測驗</DrawButton>
            <DrawButton
              onClick={() => {
                onClose()
                reset()
                setInviteId('')
              }}
            >以現在的結果分析</DrawButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FullpageHeight>
  )
}

export default withFunctions(Invite)

import React, { createElement, forwardRef } from 'react'
import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  Select,
  Textarea,
} from "@chakra-ui/react"

const SmartSelect = forwardRef(({ children = [], ...props }, ref) => {
  return (
    <Select placeholder="請選擇" {...props} ref={ref}>
      {children.map((c, i) => {
        let label = c
        let value = c
        if (typeof c === 'object') {
          label = c.label
          value = c.value
        }
        return <option value={value} key={i}>{label}</option>
      })}
    </Select>
  )
})

const types = {
  select: SmartSelect,
  textarea: Textarea,
}

const FormField = forwardRef(({
  id,
  name,
  options,
  type,
  label,
  helper,
  fieldState = {},
  ...props
}, ref) => {
  // console.log(props)
  return (
    <FormControl name={name} id={id} isInvalid={fieldState.invalid}>
      <FormLabel>{label}</FormLabel>
      {createElement(types[type] || Input, {
        ref,
        type: types[type] ? undefined : type,
        ...props
      }, options)}
      {helper && <FormHelperText>{helper}</FormHelperText>}
      <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
    </FormControl>
  )
})

export default FormField

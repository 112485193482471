const { isArray, isObject, mapValues } = require("lodash");
const xss = require("xss");

const sanitize = data => {
  if (isArray(data)) return data.map(sanitize)
  if (isObject(data)) return mapValues(data, sanitize)
  return xss(data)
}

module.exports = sanitize

import React from 'react'
import { useFunctions } from 'reactfire';

const WithFunctions = ({ Subcomp, ...props }) => {
  const functions = useFunctions()
  return <Subcomp {...props} functions={functions} />
}

const withFunctions = (Subcomp) => typeof window === 'undefined' ? Subcomp : (props => <WithFunctions {...props} Subcomp={Subcomp} />)

export default withFunctions

import React, { useEffect } from 'react'
import {
  HStack,
  IconButton,
  Text,
  useClipboard,
  useToast,
  Box,
} from "@chakra-ui/react"
import { FiLink } from 'react-icons/fi'

const CopiableText = ({ children, text, ...props }) => {
  const { hasCopied, onCopy } = useClipboard(text || children)
  const toast = useToast()
  useEffect(() => {
    toast({
      duration: 3000,
      isClosable: true,
      render: () => (
        <Box color="white" p={3} bg="love.primary">
          連結已複製到剪貼簿
        </Box>
      ),
    })
  }, [hasCopied])

  return (
    <HStack spacing="0.5" onClick={onCopy} textDecoration="underline" {...props}>
      <Text cursor="pointer">{children}</Text>
      <IconButton
        variant="ghost"
        p="0.25em"
        icon={<FiLink size="1.375em" />}
        width="1.5rem"
        minWidth="none"
        height="1.5rem"
        color="inherit"
        _hover={{ bg: 'transparent' }}
        _active={{ bg: 'transparent' }}
      />
    </HStack>
  )
}

CopiableText.Right = props => <CopiableText justifyContent="flex-end" {...props} />

export default CopiableText

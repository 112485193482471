import { Container, Flex } from '@chakra-ui/layout'
import React from 'react'

import useHeader from '../contexts/header/useHeader'

const FullpageHeight = (props) => {
  const { headerHeight } = useHeader()
  return (
    <Container
      flex="1"
      mt={`-${headerHeight}`}
      pt={headerHeight}
      width="full"
      height="full"
      maxH="100vh"
      flexDirection="column"
      alignItems="center"
      as={Flex}
    >
      <Flex
        flex="1"
        width="full"
        flexDirection="column"
        {...props}
      />
    </Container>
  )
}

export default FullpageHeight

import { Button, Text, Icon } from '@chakra-ui/react'
import React from 'react'
import { ImArrowLeft2 } from "react-icons/im";

import Link from './Link'
import { responsive } from './ThemeProvider/theme'

const DrawArrowButton = ({ to, href, children, arrowWidth, ...props }) => {
  const isLink = to || href
  return (
    <Button
      px="0"
      fontWeight="medium"
      colorScheme="green"
      fontSize={responsive('1.25em', '1.5em')}
      color="love.green"
      _focus={{ bg: 'transparent' }}
      _hover={{ bg: 'transparent' }}
      _active={{ bg: 'transparent' }}
      as={isLink && Link}
      to={to}
      href={href}
      variant="ghost"
      flexDirection="column"
      height="auto"
      minWidth="auto"
      {...props}
    >
      <Icon as={ImArrowLeft2} w="1em" h="1em" />
      {/* <DrawArrow width={arrowWidth || "2.5em"} /> */}
      {children && <Text mt="0.5em" as="span">{children}</Text>}
    </Button>
  )
}

export default DrawArrowButton
